import _ from "lodash"
import ROUTER from "src/router"

function convertTextToUrl(str) {
  if (!str) return "" // Kiểm tra chuỗi rỗng

  // Gộp nhiều dấu space thành 1 space, loại bỏ space ở 2 đầu
  str = str.trim().replace(/\s+/g, " ")

  // Loại bỏ dấu tiếng Việt
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
  str = str.replace(/đ/g, "d")
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
  str = str.replace(/Đ/g, "D")

  // Thay thế các ký tự không hợp lệ
  str = str.replace(/[^a-zA-Z0-9\s\-]/g, "") // Loại bỏ ký tự không phải chữ cái/số/dấu cách
  str = str.replace(/\s+/g, "-") // Thay thế khoảng trắng bằng dấu `-`
  str = str.replace(/-+/g, "-") // Gộp nhiều dấu `-` thành 1
  str = str.replace(/^-+|-+$/g, "") // Loại bỏ dấu `-` thừa ở đầu và cuối

  return str.toLowerCase() // Chuyển về chữ thường
}

export const routeNav = MS =>
  _.orderBy(
    routeConvert?.filter(item => !!item?.IsOnl),
    "Prefix",
    "asc",
  )

//==============================Đã có ROUTER================================
const haveRouter = [
  {
    Abstract: "Đơn yêu cầu bắt buộc chuyển giao quyền sử dụng sáng chế",
    Code: "20",
    DossierCateID: "ec048f1c-7ba1-df36-bf65-aba5bcbcbfe1",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011916",
    IsOnl: 1,
    Prefix: "LXBB",
    Router: ROUTER.TO_KHAI_YEU_CAU_BAT_BUOC_CHUYEN_GIAO_QUYEN_SU_DUNG_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Sửa đổi đơn - Sáng chế",
    Code: "6",
    DossierCateID: "9981b8ca-5f24-80b8-7b01-7dc62fed2168",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011904",
    IsOnl: 1,
    Prefix: "SĐ1",
    Router:
      ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Sửa đổi đơn - Giải pháp hữu ích",
    Code: "7",
    DossierCateID: "94d2b428-9f77-6fd5-e9a7-b8d2d10b9e9b",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011904",
    IsOnl: 1,
    Prefix: "SĐ2",
    Router:
      ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Sửa đổi đơn - Kiểu dáng công nghiệp",
    Code: "8",
    DossierCateID: "92e00d30-143a-d1f1-34d5-295381ce7c4f",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011904",
    IsOnl: 1,
    Prefix: "SĐ3",
    Router:
      ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Sửa đổi đơn - Nhãn hiệu ",
    Code: "9",
    DossierCateID: "1f83aa11-9323-ea30-d16b-54f2cc271df2",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011904",
    IsOnl: 1,
    Prefix: "SĐ4",
    Router:
      ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Sửa đổi đơn - Chỉ dẫn địa lý",
    Code: "10",
    DossierCateID: "205d219c-e48c-24e4-91ab-52a2aec6ea25",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011904",
    IsOnl: 1,
    Prefix: "SĐ6",
    Router:
      ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_CHI_DAN_DIA_LY,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Sửa đổi đơn - Thiết kế bố trí",
    Code: "11",
    DossierCateID: "a406b31a-7da6-983c-4d21-d26b774f9f76",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011904",
    IsOnl: 1,
    Prefix: "SĐ7",
    Router:
      ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký sáng chế",
    Code: "1",
    DossierCateID: "2c67bde6-5ba5-49ad-bc8f-8133ffd54b3a",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.005253",
    IsOnl: 1,
    Prefix: "1",
    Router: ROUTER.TO_KHAI_DANG_KY_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký GPHI",
    Code: "2",
    DossierCateID: "d169b4e0-8546-40d6-8006-13163651d540",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.005253",
    IsOnl: 1,
    Prefix: "2",
    Router: ROUTER.TO_KHAI_DANG_KY_GPHI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký kiểu dáng công nghiệp",
    Code: "3",
    DossierCateID: "8211b51d-39e9-41fd-b251-24dd799a86c4",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012131",
    IsOnl: 1,
    Prefix: "3",
    Router: ROUTER.TO_KHAI_DANG_KY_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký nhãn hiệu",
    Code: "4",
    DossierCateID: "dae68070-6feb-4b01-82e0-b25d26198f4e",
    Status: 1,
    CateType: 2,
    CodeTTHC: "2.002126",
    IsOnl: 1,
    Prefix: "4",
    Router: ROUTER.TO_KHAI_DANG_KY_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký chỉ dẫn địa lý",
    Code: "6",
    DossierCateID: "ff61d438-aba7-452a-873b-ca0479a1f4eb",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.005265",
    IsOnl: 1,
    Prefix: "6",
    Router: ROUTER.TO_KHAI_DANG_KY_CHI_DAN_DIA_LY,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký thiết kế bố trí",
    Code: "7",
    Router: ROUTER.TO_KHAI_DANG_KY_THIET_KE_BO_TRI_MACH_TICH_HOP_BAN_DAN,
    DossierCateID: "bd6c18cf-31d7-42bf-afbd-6cb99db01556",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.005267",
    IsOnl: 1,
    Prefix: "7",
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract:
      "Đơn đăng ký cấp chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp",
    Code: "9",
    DossierCateID: "45969f45-27ab-2c62-e291-1af2c841e1f5",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011922",
    IsOnl: 1,
    Prefix: "CCĐD",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract: "Đơn yêu cầu cấp lại Thế giám định viên sở hữu công nghiệp",
    Code: "11",
    DossierCateID: "188126b2-01dc-89a0-e1d3-406fe8bd7bb6",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011932",
    IsOnl: 1,
    Prefix: "CLGĐ",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_LAI_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract: "Đơn yêu cầu cấp Thẻ giám định viên sở hữu công nghiệp",
    Code: "2",
    DossierCateID: "bcbb7f9d-6301-073a-a831-b068638c82a7",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011931",
    IsOnl: 1,
    Prefix: "CCGĐ",
    Router: ROUTER.TO_KHAI_YEU_CAU_CAP_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu duy trì hiệu lực VBBH - Sáng chế",
    Code: "6",
    DossierCateID: "adb95837-a6f0-1a9b-9d6d-71240f3db67b",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011907",
    IsOnl: 1,
    Prefix: "DT1",
    Router: ROUTER.TO_KHAI_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu duy trì hiệu lực VBBH - Giải pháp hữu ích",
    Code: "7",
    DossierCateID: "ff73e068-90f5-87c3-2e9e-71d25cb43b2d",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011907",
    IsOnl: 1,
    Prefix: "DT2",
    Router: ROUTER.TO_KHAI_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Sáng chế",
    Code: "53",
    DossierCateID: "1271b854-7061-06b6-af33-eeaf2e48c185",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC1",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Giải pháp hữu ích",
    Code: "54",
    DossierCateID: "5b2d7a85-e466-d873-9832-8cf8620e8684",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC2",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_GIA_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Kiểu dáng công nghiệp",
    Code: "55",
    DossierCateID: "1fbde30c-70d2-f35c-c257-c31c60459449",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC3",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Nhãn hiệu",
    Code: "56",
    DossierCateID: "43914aa0-cc04-ff51-6efa-2ab99cbec8cf",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC4",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Chỉ Dẫn Địa Lý",
    Code: "57",
    DossierCateID: "df62b7c0-e950-a948-c364-fb7d6a70b709",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC6",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_CHI_DAN_DIA_LY,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Thiết kế bố trí",
    Code: "58",
    DossierCateID: "5aefeb0d-6c28-113b-ad81-34d58e5eef18",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC7",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_CHU_VBBH_TUYEN_BO_TU_BO_QUYEN_THIET_KE_BO_TRI,

    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Sáng chế",
    Code: "1",
    DossierCateID: "304af00d-056d-20da-1b3b-6a3c97f47c97",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011913",
    IsOnl: 1,
    Prefix: "CB1",
    SortOrder: 0,
    Fee: 0,
    Router: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_SANG_CHE,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Giải pháp hữu ích",
    Code: "2",
    DossierCateID: "0d815fe5-d9bd-8e41-9b6a-93d9a0c2b745",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011913",
    IsOnl: 1,
    Prefix: "CB2",
    Router:
      ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Kiểu dáng công nghiệp",
    Code: "3",
    DossierCateID: "c8f62d59-06dd-86fc-fa68-645e23732761",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011913",
    IsOnl: 1,
    Prefix: "CB3",
    Router:
      ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Nhãn hiệu",
    Code: "4",
    DossierCateID: "12aa9b6e-d750-364e-f284-0bb60ce3790d",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011913",
    IsOnl: 1,
    Prefix: "CB4",
    Router: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_NHAN_HIEU,

    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Thiết kế bố trí",
    Code: "5",
    DossierCateID: "e0ecf0f7-de78-0596-5ac8-2cb2adb57441",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011913",
    IsOnl: 1,
    Prefix: "CB7",
    Router:
      ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN -Sáng chế",
    Code: "59",
    DossierCateID: "ba5122c4-0731-c5b1-3e11-7b6176176fe8",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "ĐCLX1",
    SortOrder: 0,
    Fee: 0,
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
    Code: "60",
    DossierCateID: "d6bfa199-4499-2bc0-9aa3-ca1fea75d913",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "ĐCLX2",
    SortOrder: 0,
    Fee: 0,
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
    Code: "61",
    DossierCateID: "7c33fea0-b381-5e00-e170-ba8e82c77331",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "ĐCLX3",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
    Code: "62",
    DossierCateID: "45d9a3c5-1fdc-d6cc-275f-ba41836eb4ee",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "ĐCLX4",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế",
    Code: "63",
    DossierCateID: "2fadea68-7cc0-7838-e785-403f9d4698be",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "ĐCLX7",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "1",
    DossierCateID: "4465f7b0-c7d7-40e1-bae1-93d4b631689f",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "A",
    SortOrder: 0,
    Fee: 0,
    Router: ROUTER.TO_KHAI_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VN,
  },
  {
    Abstract: "Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "8",
    DossierCateID: "6101cef4-3949-a480-b50a-1f454dbc9ad1",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "B",
    Router: ROUTER.TO_KHAI_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VN,
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract: "Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "15",
    DossierCateID: "ad87a376-d681-4a54-03ae-97f2c3e2451b",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "P",
    SortOrder: 0,
    Fee: 0,
    Router: ROUTER.TO_KHAI_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VN,
  },
  {
    Abstract: "Đăng ký dự kiểm tra nghiệp vụ giám định sở hữu công nghiệp",
    Code: "20",
    DossierCateID: "9048e9e0-0513-f6bf-77f0-43c632088f9b",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011930",
    IsOnl: 1,
    Prefix: "KTGĐ",
    SortOrder: 0,
    Fee: 0,
    Router:
      ROUTER.TO_KHAI_THU_TUC_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_GIAM_DINH_SO_HUU_CONG_NGHIEP,
  },
  {
    Abstract: "Chuyển giao đơn - Sáng chế",
    Code: "1",
    DossierCateID: "4d1cecb7-17d5-b932-5cb9-1410b979c231",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011903",
    IsOnl: 1,
    Prefix: "CĐ1",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Chuyển giao đơn - Giải pháp hữu ích",
    Code: "2",
    DossierCateID: "6c8d07e1-079e-c790-4e60-4069d4082238",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011903",
    IsOnl: 1,
    Prefix: "CĐ2",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Chuyển giao đơn - Kiểu dáng công nghiệp",
    Code: "3",
    DossierCateID: "0bab4027-4a0e-6593-e43e-cd60f20ffe74",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011903",
    IsOnl: 1,
    Prefix: "CĐ3",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Chuyển giao đơn - Nhãn hiệu ",
    Code: "4",
    DossierCateID: "c0136681-541c-a4e1-5ce7-347de15f6d61",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011903",
    IsOnl: 1,
    Prefix: "CĐ4",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Chuyển giao đơn - Thiết kế bố trí",
    Code: "5",
    DossierCateID: "8cf262e9-41b5-9c6e-6007-c6a4d577274d",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011903",
    IsOnl: 1,
    Prefix: "CĐ7",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract: "Đơn khiếu nại - Sáng chế (Đơn liên quan tới đơn)",
    Code: "20",
    DossierCateID: "a1d9c36c-2423-fa64-f07b-08fd14dbce3a",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "KN1",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_SANG_CHE_DON_LIEN_QUAN_TOI_DON,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Giải pháp hữu ích (Đơn liên quan tới đơn)",
    Code: "21",
    DossierCateID: "567f79d8-d499-8ec8-9fed-0b3efc82d4db",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "KN2",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_GIAI_PHAP_HUU_ICH_DON_LIEN_QUAN_TOI_DON,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Kiểu dáng công nghiệp (Đơn liên quan tới đơn)",
    Code: "22",
    DossierCateID: "ad435cbe-0d5b-df1c-c4ba-68e260ea9e76",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "KN3",
    Router:
      ROUTER.TO_KHAI_KHIEU_NAI_KIEU_DANG_CONG_NGHIEP_DON_LIEN_QUAN_TOI_DON,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Nhãn hiệu (Đơn liên quan tới đơn)",
    Code: "23",
    DossierCateID: "7aa1110c-481c-cd94-3701-6446552b6d8d",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "KN4",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_NHAN_HIEU_DON_LIEN_QUAN_TOI_DON,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Nhãn hiệu quốc tế (Đơn liên quan tới đơn)",
    Code: "24",
    DossierCateID: "d99c0ff3-45c1-b7c3-1d87-323790b4f3ca",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "KN5",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_NHAN_HIEU_QUOC_TE_DON_LIEN_QUAN_TOI_DON,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Chỉ dẫn địa lý (Đơn liên quan tới đơn)",
    Code: "25",
    DossierCateID: "f78f267b-f62d-ec79-44e5-d8729f4f9c98",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "KN6",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_CHI_DAN_DIA_LY_DON_LIEN_QUAN_TOI_DON,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Thiết kế bố trí (Đơn liên quan tới đơn)",
    Code: "26",
    DossierCateID: "fb8a28c1-d19f-1e0c-e810-b1f949eaf490",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "KN7",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_THIET_KE_BO_TRI_DON_LIEN_QUAN_TOI_DON,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - LAHAY (Đơn liên quan tới đơn)",
    Code: "27",
    DossierCateID: "7cbc5054-31ca-c664-a48d-bd19a1dd213e",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.021135",
    IsOnl: 1,
    Prefix: "KN8",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_LAHAY_DON_LIEN_QUAN_TOI_DON,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Sáng chế (Đơn liên quan tới văn bằng)",
    Code: "73",
    DossierCateID: "4a20dfff-f475-43e2-32e1-123012ceadee",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.021135",
    IsOnl: 1,
    Prefix: "KN1",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_SANG_CHE_DON_LIEN_QUAN_TOI_VAN_BANG,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Giải pháp hữu ích (Đơn liên quan tới văn bằng)",
    Code: "74",
    DossierCateID: "102ca637-4b5c-6c4e-b309-9e13736c1e99",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.021135",
    IsOnl: 1,
    Prefix: "KN2",
    Router:
      ROUTER.TO_KHAI_KHIEU_NAI_GIAI_PHAP_HUU_ICH_DON_LIEN_QUAN_TOI_VAN_BANG,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn khiếu nại - Kiểu dáng công nghiệp (Đơn liên quan tới văn bằng)",
    Code: "75",
    DossierCateID: "127fabd6-d272-8f03-7d17-eb26033e9684",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.021135",
    IsOnl: 1,
    Prefix: "KN3",
    Router:
      ROUTER.TO_KHAI_KHIEU_NAI_KIEU_DANG_CONG_NGHIEP_DON_LIEN_QUAN_TOI_VAN_BANG,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Nhãn hiệu (Đơn liên quan tới văn bằng)",
    Code: "76",
    DossierCateID: "39534979-8134-29f6-ea0b-a1386a723834",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.021135",
    IsOnl: 1,
    Prefix: "KN4",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_NHAN_HIEU_DON_LIEN_QUAN_TOI_VAN_BANG,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Nhãn hiệu quốc tế (Đơn liên quan tới văn bằng)",
    Code: "77",
    DossierCateID: "5d551509-a5fe-8d10-4ad8-adc0efc9d820",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.021135",
    IsOnl: 1,
    Prefix: "KN5",
    Router:
      ROUTER.TO_KHAI_KHIEU_NAI_NHAN_HIEU_QUOC_TE_DON_LIEN_QUAN_TOI_VAN_BANG,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Chỉ dẫn địa lý (Đơn liên quan tới văn bằng)",
    Code: "78",
    DossierCateID: "36020f7b-3649-93ab-45d7-99276e344252",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.021135",
    IsOnl: 1,
    Prefix: "KN6",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_CHI_DAN_DIA_LY_DON_LIEN_QUAN_TOI_VAN_BANG,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - Thiết kế bố trí (Đơn liên quan tới văn bằng)",
    Code: "79",
    DossierCateID: "a88bb01c-a7bc-f99e-a13c-8a5b0d815e9b",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.021135",
    IsOnl: 1,
    Prefix: "KN7",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_THIET_KE_BO_TRI_DON_LIEN_QUAN_TOI_VAN_BANG,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn khiếu nại - LAHAY (Đơn liên quan tới văn bằng)",
    Code: "80",
    DossierCateID: "9f1a9701-88f6-6557-c483-cb21b015926f",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "KN8",
    Router: ROUTER.TO_KHAI_KHIEU_NAI_LAHAY_DON_LIEN_QUAN_TOI_VAN_BANG,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đăng ký dự kiểm tra nghiệp vụ đại diện sở hữu công nghiệp",
    Code: "21",
    DossierCateID: "e4834eef-f4d4-b3eb-701a-d370494bce17",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011921",
    IsOnl: 1,
    Prefix: "KTNV",
    Router:
      ROUTER.TO_KHAI_DANG_KY_KIEM_TRA_NGHIEP_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu sửa đổi VBBH - Sáng chế",
    Code: "42",
    DossierCateID: "7adf4807-749a-53bd-748c-e0b922c2cb36",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011911",
    IsOnl: 1,
    Prefix: "SB1",
    Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract: "Đơn yêu cầu sửa đổi VBBH - Giải pháp hữu ích",
    Code: "43",
    DossierCateID: "67c4081e-ffd3-de1b-7dc5-80df87d94ff6",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011911",
    IsOnl: 1,
    Prefix: "SB2",
    Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu sửa đổi VBBH - Kiểu dáng công nghiệp",
    Code: "44",
    DossierCateID: "269f9ccb-a4e6-c430-0387-cd3b336c4660",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011911",
    IsOnl: 1,
    Prefix: "SB3",
    Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu sửa đổi VBBH - Nhãn hiệu",
    Code: "45",
    DossierCateID: "b0ed164b-632d-06bf-b662-a63adf5fd632",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011911",
    IsOnl: 1,
    Prefix: "SB4",
    Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu sửa đổi VBBH - Chỉ Dẫn Địa Lý",
    Code: "46",
    DossierCateID: "a3ba1e44-6522-c7f7-3034-d15b28d4b6dc",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011911",
    IsOnl: 1,
    Prefix: "SB6",
    Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_CHI_DAN_DIA_LY,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu sửa đổi VBBH - Thiết kế bố trí",
    Code: "47",
    DossierCateID: "25aae410-e1d3-d1c4-0cc2-bb3fec6ce585",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011911",
    IsOnl: 1,
    Prefix: "SB7",
    Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký ghi nhận thay đổi về tổ chức dịch vụ đại diện sở hữu công nghiệp/người đại diện SHCN",
    Code: "24",
    DossierCateID: "dd86b56e-dd95-9340-6352-82c125cef47c",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011927",
    IsOnl: 1,
    Prefix: "SĐĐD",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_THONG_TIN_CUA_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký cấp lại chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp",
    Code: "10",
    DossierCateID: "3c34ad89-b6bc-f0ae-4fc7-62c5bddfaaba",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011923",
    IsOnl: 1,
    Prefix: "CLCC",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_LAI_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
    Code: "15",
    DossierCateID: "e40a5696-b9c5-f085-1e4d-6a76330ca06d",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011914",
    IsOnl: 1,
    Prefix: "LX1",
    Router:
      ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
    Code: "16",
    DossierCateID: "7733aac5-a8a1-6082-2659-2dcdda360ad3",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011914",
    IsOnl: 1,
    Prefix: "LX2",
    Router:
      ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
    Code: "17",
    DossierCateID: "6940b6da-31fa-01fa-c095-43c596bf9ae0",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011914",
    IsOnl: 1,
    Prefix: "LX3",
    Router:
      ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
    Code: "18",
    DossierCateID: "8eff6a5c-de67-f52c-14c9-454a1b14774f",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011914",
    IsOnl: 1,
    Prefix: "LX4",
    Router:
      ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí",
    Code: "19",
    DossierCateID: "d8895de7-cb9d-4dbc-9119-19f87e77111b",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011914",
    IsOnl: 1,
    Prefix: "LX7",
    Router:
      ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn đăng ký ghi nhận tổ chức dịch vụ đại diện sở hữu công nghiệp",
    Code: "27",
    DossierCateID: "b24d55aa-936c-43f5-1b67-aa495b2409c7",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011926",
    IsOnl: 1,
    Prefix: "TCĐD",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_TO_CHUC_DU_DIEU_KIEN_KINH_DOANH_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu gia hạn hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
    Code: "10",
    DossierCateID: "5b0f7593-8d75-1358-7ab6-21cec78a31c0",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "GHLX1",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu gia hạn hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
    Code: "11",
    DossierCateID: "35f5c54d-cba7-2f64-7b67-f83262b77e06",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "GHLX2",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu gia hạn hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
    Code: "12",
    DossierCateID: "ae9b4398-46da-d3f8-93e3-3130787fc1a5",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "GHLX3",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu gia hạn hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
    Code: "13",
    DossierCateID: "1649458b-3f89-4673-0176-cb1717923370",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "GHLX4",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu gia hạn hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí",
    Code: "14",
    DossierCateID: "c33b11cc-18ca-cc1f-5ca3-5df1d18bdfbe",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "GHLX7",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GIA_HAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
    Code: "48",
    DossierCateID: "cc16d7be-be43-cda0-990f-45cb71c27aca",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "SĐLX1",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
    Code: "49",
    DossierCateID: "93dc6d16-e287-2b52-3113-943a6965dd97",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "SĐLX2",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
    Code: "50",
    DossierCateID: "825b11c3-4de9-3a11-0e79-cc915c8f8f9b",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "SĐLX3",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu quốc gia",
    Code: "51",
    DossierCateID: "54de52d9-ef1d-1c0f-0522-30578fd6dcb8",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "SĐLX4",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí",
    Code: "52",
    DossierCateID: "067615b4-cb12-9e43-36de-6ce875cb53ea",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011915",
    IsOnl: 1,
    Prefix: "SĐLX7",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_NOI_DUNG_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH",
    Code: "65",
    DossierCateID: "c4319c46-14db-bd52-d989-d28b49e4e76c",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐN1",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Giải pháp hữu ích",
    Code: "66",
    DossierCateID: "119c4ca6-452e-f4e2-541e-390554287380",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐN2",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_GIA_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Kiểu dáng công nghiệp",
    Code: "67",
    DossierCateID: "4a022225-db62-0b3d-c66d-afc4206b4e1a",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐN3",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Nhãn hiệu",
    Code: "68",
    DossierCateID: "d2af15fd-d69d-db40-00d6-e66fe68fc430",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐN4",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Nhãn hiệu quốc tế",
    Code: "69",
    DossierCateID: "61bd31eb-7f7c-4b79-bb3c-7006acbdce89",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐN5",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_NHAN_HIEU_QUOC_TE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Chỉ dẫn địa lý",
    Code: "70",
    DossierCateID: "7b2af908-297b-4497-a029-65069dbd4afb",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐN6",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_CHI_DAN_DIA_LY,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Thiết kế bố trí",
    Code: "71",
    DossierCateID: "e6ba819f-803f-480e-8e65-118af0521de5",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐN7",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - LAHAY",
    Code: "72",
    DossierCateID: "58cbf500-c9b4-460b-b3b9-f446a3a0663d",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐN8",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CHAM_DUT_HIEU_LUC_VBBH_DO_BEN_THU_BA_YEU_CAU_LAHAY,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp Giấy chứng nhận tổ chức đủ điều kiện hoạt động giám định sở hữu công nghiệp",
    Code: "25",
    DossierCateID: "0ff3370e-827c-68b7-8a22-3d52cecd14d6",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "TCGĐ",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_GIAY_CHUNG_NHAN_TO_CHUC_DU_DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp phó bản VBBH - Sáng chế",
    Code: "21",
    DossierCateID: "ed2e648a-1ea0-652c-25f6-d42b02b72f0e",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "PB1",
    Router:
      ROUTER.TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp phó bản VBBH - Giải pháp hữu ích",
    Code: "22",
    DossierCateID: "f50ea2ff-fb6d-a151-4f4a-f1b2284c90ba",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "PB2",
    Router:
      ROUTER.TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp phó bản VBBH - Kiểu dáng công nghiệp ",
    Code: "23",
    DossierCateID: "9c81afa1-59d1-befe-73cc-17052facc200",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "PB3",
    Router:
      ROUTER.TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp phó bản VBBH  Nhãn hiệu",
    Code: "24",
    DossierCateID: "ab3f73bd-f4b6-3976-b6e0-4813736c0b26",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "PB4",
    Router:
      ROUTER.TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp phó bản VBBH - Thiết kế bố trí ",
    Code: "25",
    DossierCateID: "a825d361-4d04-54cd-92b8-7f123a7b0306",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "PB7",
    Router:
      ROUTER.TO_KHAI_CAP_PHO_BAN_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại VBBH - Sáng chế",
    Code: "31",
    DossierCateID: "deddb430-a689-1dcc-9bc9-0e818ab315f0",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "RB1",
    Router: ROUTER.TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại VBBH - Giải pháp hữu ích",
    Code: "32",
    DossierCateID: "6d2db3cc-0efa-5b9f-6096-71c8906fe950",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "RB2",
    Router: ROUTER.TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại VBBH - Kiểu dáng công nghiệp",
    Code: "33",
    DossierCateID: "02a3966a-3347-8ce0-e700-d21babf3cb84",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "RB3",
    Router: ROUTER.TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại VBBH - Nhãn hiệu",
    Code: "34",
    DossierCateID: "5758294d-a06e-2425-a79d-c0210d3eef8b",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "RB4",
    Router: ROUTER.TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại VBBH - Chỉ Dẫn Địa Lý",
    Code: "35",
    DossierCateID: "d99adaed-efd1-da4f-8c0e-fac69e0e2941",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "RB6",
    Router: ROUTER.TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_CHI_DAN_DIA_LY,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại VBBH - Thiết kế bố trí",
    Code: "36",
    DossierCateID: "dd97693b-8298-dafc-463a-ae6b8ab8efb9",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011912",
    IsOnl: 1,
    Prefix: "RB7",
    Router: ROUTER.TO_KHAI_CAP_LAI_VAN_BANG_BAO_HO_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Ghi nhận đăng bạ - Sáng chế",
    Code: "14",
    DossierCateID: "7818cd0e-ded6-21ea-f6ae-6d1556bb2425",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "GNĐB1",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Ghi nhận đăng bạ - Giải pháp hữu ích",
    Code: "15",
    DossierCateID: "bfead3a3-fb62-488f-a018-b6dee4291d01",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "GNĐB2",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Ghi nhận đăng bạ - Kiểu dáng công nghiệp",
    Code: "16",
    DossierCateID: "9f0ac220-dcc3-4083-99bb-7055b9252b60",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "GNĐB3",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Ghi nhận đăng bạ - Nhãn hiệu",
    Code: "17",
    DossierCateID: "8cec2d72-95b7-4a98-9aee-cfef5b4750f4",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "GNĐB4",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Ghi nhận đăng bạ - Chỉ dẫn địa lý",
    Code: "18",
    DossierCateID: "96efc67a-61d4-4aae-bc09-7ed9aea1f360",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "GNĐB6",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_CHI_DAN_DIA_LY,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Ghi nhận đăng bạ - Thiết kế bố trí",
    Code: "19",
    DossierCateID: "2ca349e7-6b1a-49ba-b57a-a3531b28b790",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "GNĐB7",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu gia hạn hiệu lực VBBH - Kiểu dáng công nghiệp",
    Code: "8",
    DossierCateID: "bfabcbaa-c154-acf3-d714-cb5f689496f8",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011908",
    IsOnl: 1,
    Prefix: "GH3",
    Router: ROUTER.TO_KHAI_GIA_HAN_VAN_BANG_BAO_HO_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu gia hạn hiệu lực VBBH - Nhãn hiệu",
    Code: "9",
    DossierCateID: "f43ba6ce-e28d-29f4-3a77-ddcc895c7304",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011908",
    IsOnl: 1,
    Prefix: "GH4",
    Router: ROUTER.TO_KHAI_GIA_HAN_VAN_BANG_BAO_HO_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
    Code: "37",
    DossierCateID: "091c0227-c6a4-62ab-bc36-435ecb4404cf",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "RBLX1",
    Router:
      ROUTER.TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
    Code: "38",
    DossierCateID: "0a591513-a789-fe02-86c9-0a7d5e7373ea",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "RBLX2",
    Router:
      ROUTER.TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
    Code: "39",
    DossierCateID: "b3f52418-7f56-3421-c9ec-cc3f836cb1b2",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "RBLX3",
    Router:
      ROUTER.TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
    Code: "40",
    DossierCateID: "3b11b0ac-ff0c-5999-6238-e63018111f83",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "RBLX4",
    Router:
      ROUTER.TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế",
    Code: "41",
    DossierCateID: "a9bebad7-b511-689c-4980-8336c29a5d77",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "RBLX7",
    Router:
      ROUTER.TO_KHAI_CAP_LAI_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_THIET_KE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại phó bản VBBH - Sáng chế",
    Code: "81",
    DossierCateID: "ee7e01ea-5775-4058-81c8-641d28f725fd",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "RBPB1",
    Router: ROUTER.TO_KHAI_CAP_LAI_PHO_BAN_VBBH_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại phó bản VBBH - Giải pháp hữu ích",
    Code: "82",
    DossierCateID: "a43b301d-2f32-43be-9905-cf47d0df4704",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "RBPB2",
    Router: ROUTER.TO_KHAI_CAP_LAI_PHO_BAN_VBBH_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại phó bản VBBH - Kiểu dáng công nghiệp",
    Code: "83",
    DossierCateID: "0d0d80c9-ef08-4a77-91cc-f1f743c824a9",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "RBPB3",
    Router: ROUTER.TO_KHAI_CAP_LAI_PHO_BAN_VBBH_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại phó bản VBBH - Nhãn hiệu",
    Code: "84",
    DossierCateID: "fd8dae27-5572-45e4-8e97-cb40e12b7bf8",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "RBPB4",
    Router: ROUTER.TO_KHAI_CAP_LAI_PHO_BAN_VBBH_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp lại phó bản VBBH - Thiết kế bố trí",
    Code: "85",
    DossierCateID: "77d86b31-c0f9-48c0-b75e-71c3eded2ba4",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "RBPB7",
    Router: ROUTER.TO_KHAI_CAP_LAI_PHO_BAN_VBBH_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
    Code: "26",
    DossierCateID: "e2297f7d-f2d0-ba1a-81e0-0576a1fc3b92",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "PBLX1",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_SANG_CHE,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
    Code: "27",
    DossierCateID: "186c94af-3700-5856-5264-5f30d32eb0f5",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "PBLX2",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_GIAI_PHAP_HUU_ICH,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
    Code: "28",
    DossierCateID: "fd49eeb8-45a8-648e-5d42-362e355924c3",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "PBLX3",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_KIEU_DANG_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
    Code: "29",
    DossierCateID: "9b3a6ecd-e2ba-d297-23e3-ad7d70a3e8c4",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "PBLX4",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_NHAN_HIEU,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí",
    Code: "30",
    DossierCateID: "4dd2e4ad-bb44-f6b7-afbf-7c33f8360169",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011918",
    IsOnl: 1,
    Prefix: "PBLX7",

    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_PHO_BAN_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN_THIET_KE_BO_TRI,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu giới hạn, hạn chế danh mục sản phẩm, dịch vụ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt",
    Code: "18",
    DossierCateID: "6de9d885-8a0d-c15b-0dba-d96f3421fc20",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "PL",
    Router:
      ROUTER.TO_KHAI_YEU_CAP_GIOI_HAN_HAN_CHE_DANH_MUC_SAN_PHAM_DICH_VU_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "19",
    DossierCateID: "8a2e17f2-4fc7-f233-b5fe-714e766836a4",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "PM",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_SUA_DOI_TEN_DIA_CHI_DAI_DIEN_SHCN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu gia hạn đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "23",
    DossierCateID: "605e0949-4708-4815-b0b1-13ee8dd952fe",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "PR",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GIA_HAN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "20",
    DossierCateID: "7b9822c3-788f-dd4e-a133-0616ef4aa317",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "PS",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_MO_RONG_LANH_THO_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn chuyển nhượng đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "21",
    DossierCateID: "b68d0f4c-a5c8-0712-f285-677f8ced0161",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "PT",
    Router:
      ROUTER.TO_KHAI_CHUYEN_NHUONG_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu cấp lại Giấy chứng nhận tổ chức đủ điều kiện hoạt động giám định sở hữu công nghiệp",
    Code: "23",
    DossierCateID: "b55a859a-dab5-614e-3401-82e626631ef1",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "SĐGĐ",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_CAP_LAI_GIAY_CHUNG_NHAN_TO__CHUC_DU__DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn hủy bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "16",
    DossierCateID: "f1cee313-af75-493d-338f-2fcaec29b776",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "PC",
    Router:
      ROUTER.TO_KHAI_HUY_BO_HIEU_LUC_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn huỷ bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "2",
    DossierCateID: "dedfce30-c52e-4b80-8830-f1a34d0f0b88",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "AC",
    Router:
      ROUTER.TO_KHAI_HUY_BO_HIEU_LUC_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu giới hạn, hạn chế danh mục sản phẩm, dịch vụ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt",
    Code: "3",
    DossierCateID: "1d00c8ee-912e-6da3-136f-6ca440b9a598",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "AL",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GIOI_HAN_HAN_CHE_DANH_MUC_SAN_PHAM_DICH_VU_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "4",
    DossierCateID: "09cf9295-2c27-1a35-705a-a1e7f28d8856",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "AM",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_SUA_DOI_TEN_DIA_CHI_DAI_DIEN_SHCN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu gia hạn đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "5",
    DossierCateID: "6d74b3ba-060c-a7b7-732c-674d471ab3cc",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "AR",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GIA_HAN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "6",
    DossierCateID: "85307567-a641-2640-4138-ff315dee707e",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "AS",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_MO_RONG_LANH_THO_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn chuyển nhượng đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "7",
    DossierCateID: "e6e7ccd8-43c2-2d57-1a95-f107f3dfb2aa",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "AT",
    Router:
      ROUTER.TO_KHAI_CHUYEN_NHUONG_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn hủy bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "9",
    DossierCateID: "85e04e3c-f1ed-fd72-9b02-1a5fe01dc5eb",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "BC",
    Router:
      ROUTER.TO_KHAI_HUY_BO_HIEU_LUC_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu giới hạn, hạn chế danh mục sản phẩm, dịch vụ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "22",
    DossierCateID: "20dd66de-e072-400c-9ef0-a7950de9e723",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "BL",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GIOI_HAN_HAN_CHE_DANH_MUC_SAN_PHAM_DICH_VU_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "10",
    DossierCateID: "db2387b9-e7ba-3174-1bc6-592aca9a65ab",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "BM",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_SUA_DOI_TEN_DIA_CHI_DAI_DIEN_SHCN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu gia hạn đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "11",
    DossierCateID: "3dbded9a-82dc-fb5e-0461-1ebff5f038ce",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "BR",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_GIA_HAN_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "12",
    DossierCateID: "e2dbefd7-5c24-3ff2-5df5-7ab54bf8d74b",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "BS",
    Router:
      ROUTER.TO_KHAI_YEU_CAU_MO_RONG_LANH_THO_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn chuyển nhượng đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
    Code: "13",
    DossierCateID: "20a45b2a-cbd1-a6a1-0f97-be212aa645b8",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "BT",
    Router:
      ROUTER.TO_KHAI_CHUYEN_NHUONG_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
    SortOrder: 0,
    Fee: 0,
  },
]

//==============================Chưa có ROUTER================================
const noRouter = [
  {
    Abstract: "Thủ tục rút đơn đăng ký SHCN",
    router: "",
    DossierCateID: "04ba4459-ba53-4eec-85d1-a873bd0c027a",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011906",
    IsOnl: 1,
    Prefix: "RĐ7",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Thủ tục rút đơn đăng ký SHCN",
    Code: "41",
    DossierCateID: "14398226-ce3f-41a4-9152-ea4eaaf6f57d",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011906",
    IsOnl: 1,
    Prefix: "RĐ6",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Thủ tục rút đơn đăng ký SHCN",
    Code: "42",
    DossierCateID: "24e68a54-0ba5-417b-88b8-c46f1905ea2e",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011906",
    IsOnl: 1,
    Prefix: "RĐ1",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Thủ tục rút đơn đăng ký SHCN",
    Code: "43",
    DossierCateID: "2c80ab52-08c9-4cf1-8602-c13fe6ea1972",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011906",
    IsOnl: 1,
    Prefix: "RĐ2",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Thủ tục rút đơn đăng ký SHCN",
    Code: "44",
    DossierCateID: "3c6af8c1-ce76-4113-924a-fb0489cdd778",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011906",
    IsOnl: 1,
    Prefix: "RĐ4",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Thủ tục xử lý Đơn Madrid có chỉ định Việt Nam",
    Code: "5",
    DossierCateID: "97b02609-1857-11ef-b27f-00163e0a2c9c",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "5",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Thủ tục xử lý Đơn La Hay có chỉ định Việt Nam",
    Code: "8",
    DossierCateID: "c8f6988a-1851-11ef-b27f-00163e0a2c9c",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "8",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Thủ tục rút đơn đăng ký SHCN",
    Code: "45",
    DossierCateID: "ec928ad7-b2b0-42b0-8094-f7ee0635112c",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011906",
    IsOnl: 1,
    Prefix: "RĐ3",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký quốc tế KDCN có nguồn gốc Việt Nam",
    Code: "14",
    DossierCateID: "a33ad2c7-c858-ffb2-462d-6067fd0f089c",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "H",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn đăng ký sáng chế theo Hiệp ước PCT có nguồn gốc Việt Nam",
    Code: "17",
    DossierCateID: "afb194e3-2b25-bd7d-c491-9525421b6b41",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "PCT/VN",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu tra cứu - Sáng chế",
    Code: "28",
    DossierCateID: "cc40336e-5c93-417a-bb08-097cbd540b7b",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "TC1",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu tra cứu - Giải pháp hữu ích",
    Code: "29",
    DossierCateID: "eac9835f-0509-44c6-82db-c300dc924132",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "TC2",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu tra cứu - Kiểu dáng công nghiệp",
    Code: "30",
    DossierCateID: "2493b497-611b-4781-b31d-ea8ee2329ed2",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "TC3",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu tra cứu - Nhãn hiệu",
    Code: "31",
    DossierCateID: "fbb75b14-a638-40fd-b03f-d792bf021044",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "TC4",
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract: "Đơn phản đối - Sáng chế",
    Code: "12",
    DossierCateID: "da93803d-474e-5610-6e8f-28737b04e2d0",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012134",
    IsOnl: 1,
    Prefix: "PĐ1",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn phản đối - Giải pháp hữu ích",
    Code: "13",
    DossierCateID: "1a8a05eb-af2f-58e2-d3f6-1579e5e26384",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012134",
    IsOnl: 1,
    Prefix: "PĐ2",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn phản đối - Kiểu dáng công nghiệp",
    Code: "14",
    DossierCateID: "40fd5853-34c9-9189-deee-9558d4918650",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012134",
    IsOnl: 1,
    Prefix: "PĐ3",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn phản đối - Nhãn hiệu",
    Code: "15",
    DossierCateID: "b0124e67-68e9-88c7-d123-7bbce68967e0",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012134",
    IsOnl: 1,
    Prefix: "PĐ4",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn phản đối - Nhãn hiệu quốc tế",
    Code: "16",
    DossierCateID: "be1049cc-5b6c-3726-7452-c31f515b8fe6",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012134",
    IsOnl: 1,
    Prefix: "PĐ5",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn phản đối - Chỉ dẫn địa lý",
    Code: "17",
    DossierCateID: "f3ef3c14-a14d-609e-7079-3890bcfcdb5a",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012134",
    IsOnl: 1,
    Prefix: "PĐ6",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn phản đối - Thiết kế bố trí",
    Code: "18",
    DossierCateID: "3e44ed86-cbec-1bba-e3c8-ab2d403d7ddf",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012134",
    IsOnl: 1,
    Prefix: "PĐ7",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn phản đối - LAHAY",
    Code: "19",
    DossierCateID: "d03b8952-c2dc-1b0a-9290-de0b3dffe0e4",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.012134",
    IsOnl: 1,
    Prefix: "PĐ8",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Giải pháp hữu ích",
    Code: "54",
    DossierCateID: "5b2d7a85-e466-d873-9832-8cf8620e8684",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC2",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Kiểu dáng công nghiệp",
    Code: "55",
    DossierCateID: "1fbde30c-70d2-f35c-c257-c31c60459449",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC3",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Nhãn hiệu",
    Code: "56",
    DossierCateID: "43914aa0-cc04-ff51-6efa-2ab99cbec8cf",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC4",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Chỉ Dẫn Địa Lý",
    Code: "57",
    DossierCateID: "df62b7c0-e950-a948-c364-fb7d6a70b709",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC6",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Thiết kế bố trí",
    Code: "58",
    DossierCateID: "5aefeb0d-6c28-113b-ad81-34d58e5eef18",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "ĐC7",
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract:
      "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - LI XĂNG BẮT BUỘC",
    Code: "64",
    DossierCateID: "805a874e-cac6-80f7-5297-52789aa539d0",
    Status: 1,
    CateType: 2,
    CodeTTHC: "1.011917",
    IsOnl: 1,
    Prefix: "ĐCLXBB",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cấp bản sao tài liệu thông tin sở hữu công nghiệp",
    Code: "1",
    DossierCateID: "1f9d9dff-6c04-e522-c6ec-f95b8c4b3a3f",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "BSTL",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cung cấp thông tin SHCN - Sáng chế",
    Code: "3",
    DossierCateID: "2c4fa926-6fc7-b582-ecdb-3765e91b4303",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "CCTT1",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cung cấp thông tin SHCN - GPHI",
    Code: "4",
    DossierCateID: "89798533-6e5e-7f7d-d9c2-aca2171f0903",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "CCTT2",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cung cấp thông tin SHCN - Kiểu dáng CN",
    Code: "5",
    DossierCateID: "654c0321-557d-0784-206d-534e8f9a6522",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "CCTT3",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cung cấp thông tin SHCN - Nhãn hiệu",
    Code: "6",
    DossierCateID: "bca492d4-c60a-5447-3425-e547653c37bb",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "CCTT4",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cung cấp thông tin SHCN - Chỉ dẫn địa lý",
    Code: "7",
    DossierCateID: "efeba252-a44d-9b05-a628-8dbcfc29d120",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "CCTT6",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Đơn yêu cầu cung cấp thông tin SHCN - Thiết kế bố trí",
    Code: "8",
    DossierCateID: "71ea32f4-7efa-e877-e580-36801304fa36",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "CCTT7",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Ghi nhận gia hạn - Kiểu dáng công nghiệp",
    Code: "12",
    DossierCateID: "9e8dc6d0-a2e7-5d70-eb2e-760516d4e625",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "GNGH3",
    SortOrder: 0,
    Fee: 0,
  },
  {
    Abstract: "Ghi nhận gia hạn - Nhãn hiệu",
    Code: "13",
    DossierCateID: "216c7286-7261-4981-bbd8-384640b8e9a8",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "GNGH4",
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract: "Đăng ký phúc tra nghiệp vụ đại diện sở hữu công nghiệp",
    Code: "22",
    DossierCateID: "ba130d88-4671-45c4-2ed9-9189277d26fb",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "PTĐD",
    SortOrder: 0,
    Fee: 0,
  },

  {
    Abstract: "Đơn yêu cầu xác nhận ĐKQTNH được chấp nhận bảo hộ tại Việt Nam",
    Code: "26",
    DossierCateID: "07b8ca1d-7786-c7bc-2112-dd12640035a4",
    Status: 1,
    CateType: 2,
    IsOnl: 1,
    Prefix: "XNQT",
    SortOrder: 0,
    Fee: 0,
  },
]

const routeInit = [...haveRouter, ...noRouter]
const routeConvert = routeInit
  // .filter(i => (!!MS ? (i.Number = "== MS ") : true))
  .map(item => ({
    ...item,
    Number: item?.CodeTTHC,
    name: item?.Abstract,
  }))
